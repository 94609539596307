import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useMainStore } from '../stores/mainStore';
import { getImageSrc } from '../utils';
import {
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Avatar,
  Typography,
  CircularProgress,
} from '@mui/material';
import styled from '@emotion/styled';

const StyledListItem = styled(ListItem)`
  &:hover {
    background-color: rgba(0, 0, 0, 0.04);
    cursor: pointer;
  }
`;

export const Inbox: React.FC = () => {
  const navigate = useNavigate();
  const { inbox, getInbox, characters, getCharacters } = useMainStore();

  useEffect(() => {
    getInbox();
    getCharacters();
  }, []);

  if (!inbox) {
    return (
      <div className="flex justify-center items-center h-full">
        <CircularProgress />
      </div>
    );
  }

  return (
    <div className="flex flex-col app-container">
      <div className="p-4 border-b">
        <Typography variant="h6">Messages</Typography>
      </div>
      <List className="flex-grow overflow-y-auto">
        {inbox.map((item) => (
          <StyledListItem
            key={item._id}
            onClick={() => navigate(`/app/chat/${item._id}`)}
          >
            <ListItemAvatar>
              <Avatar
                src={getImageSrc(
                  (
                    characters.find((char) => char._id === item._id)?.images ||
                    ''
                  ).split('|')[0]
                )}
                alt={item.name}
              />
            </ListItemAvatar>
            <ListItemText
              primary={item.name}
              secondary={
                <Typography
                  component="span"
                  variant="body2"
                  color="textSecondary"
                  sx={{
                    display: 'block',
                    maxWidth: '100%',
                    whiteSpace: 'nowrap',
                    textOverflow: 'ellipsis',
                    overflow: 'hidden',
                  }}
                >
                  {item.message}
                </Typography>
              }
            />
          </StyledListItem>
        ))}
      </List>
    </div>
  );
};
