import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  IconButton,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useMainStore } from '../stores/mainStore';
import { Avatar } from '@mui/material';
import { getImageSrc } from '../utils';
import { useEffect } from 'react';

export const ErrorModal = () => {
  const error = useMainStore((state) => state.error);
  const cause = useMainStore((state) => state.cause);
  const clearError = useMainStore((state) => state.clearError);
  const stopTyping = useMainStore((state) => state.stopTyping);
  const activeCharacter = useMainStore((state) => state.activeCharacter);
  const me = useMainStore((state) => state.me);
  const getMe = useMainStore((state) => state.getMe);
  useEffect(() => {
    getMe();
  }, []);
  return (
    <Dialog open={!!error} onClose={() => clearError()}>
      <DialogTitle>
        <IconButton
          aria-label="close"
          onClick={() => {
            clearError();
            stopTyping();
          }}
          style={{ position: 'absolute', right: 8, top: 8 }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <div style={{ maxWidth: '300px' }}>
          <Typography
            style={{
              textAlign: 'center',
              padding: '15px',
              float: 'left',
              maxWidth: '300px',
            }}
          >
            {error}
          </Typography>
          {cause && cause === 'score' && (
            <div
              style={{
                maxWidth: '300px',
                width: '100%',
                display: 'flex',
                justifyContent: 'space-evenly',
              }}
            >
              {[activeCharacter, me].map((character) => (
                <div className="flex flex-col items-center gap-2 relative float-left">
                  <Avatar
                    alt={character?.name}
                    src={getImageSrc(character?.images)}
                    sx={{ width: 90, height: 90, marginTop: '25px' }}
                  />
                  <div
                    style={{
                      background: 'linear-gradient(to top, #fde047, #f97316)',
                      width: '80px',
                      height: '80px',
                      clipPath:
                        'polygon(50% 0%, 61% 35%, 98% 35%, 68% 57%, 79% 91%, 50% 70%, 21% 91%, 32% 57%, 2% 35%, 39% 35%)',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      color: 'white',
                      fontSize: '1.2rem',
                      position: 'absolute',
                      top: '50px',
                      left: '40px',
                    }}
                  >
                    {Math.floor((character?.score || 0) / 30) + 1}
                  </div>
                </div>
              ))}
            </div>
          )}
          {cause && cause === 'patreon' && (
            <a
              target="_blank"
              className="flex justify-center p-2 pt-0"
              href="https://www.patreon.com/lewdSpace"
            >
              <img
                src="/public/patreon.png"
                alt="Support me on Patreon"
                style={{ borderRadius: '10px' }}
              />
            </a>
          )}
        </div>
      </DialogContent>
      {!cause && (
        <DialogActions style={{ padding: '15px' }}>
          <Button onClick={() => clearError()}>Close</Button>
          <Button
            onClick={() => window.location.reload()}
            variant="contained"
            color="primary"
          >
            Reload
          </Button>
        </DialogActions>
      )}
    </Dialog>
  );
};
