import { Outlet } from 'react-router-dom';
import { BottomNav } from './BottomNav';
import { AppHeader } from './AppHeader';

export const AppLayout = () => {
  return (
    <div className="my-auto w-full" style={{ height: 'min-content' }}>
      <div className="overflow-hidden flex items-center justify-center from-yellow-50 via-teal-300 to-yellow-50 bg-gradient-to-br">
        <div className="flex flex-col">
          <div className="w-screen md:w-[450px] px-7 py-7 mx-auto bg-white rounded-2xl shadow-xl">
            <div className="max-w-md mx-auto space-y-6">
              <div className="flex flex-col relative">
                <AppHeader />
                <Outlet />
                <BottomNav />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
