import { Link } from 'react-router-dom';
import { useMainStore } from '../stores/mainStore';
import LogoutIcon from '@mui/icons-material/Logout';

export const AppHeader = () => {
  const tier = useMainStore((state) => state.tier);
  const logout = useMainStore((state) => state.logout);
  return (
    <div className="flex border-b pb-1 border-gray-100 justify-between">
      <div className="">
        <Link to="/">
          <img
            src="/public/logo.png"
            style={{ height: '40px', width: '126px' }}
          />
        </Link>
      </div>
      <div className="flex space-x-4">
        {!tier ? (
          <a
            target="_top"
            href={`https://www.patreon.com/oauth2/authorize?response_type=code&client_id=1LVj7EWwjKSffaqkuJlF3bqqF6Xx9L9YWv-ZrXdJdkqwanrhcikf5DFIxjmHV9JF&redirect_uri=https://lewdspace.com/api/patreon&scope=identity%20identity%5Bemail%5D&state=${
              localStorage.getItem('token') || 'null'
            }`}
          >
            <img
              style={{ width: '100px', borderRadius: '10px' }}
              src="/public/patreonLoginSmall.png"
              alt="Patreon login"
            />
          </a>
        ) : (
          <button
            onClick={logout}
            className="px-4 py-2 bg-red-500 text-white rounded hover:bg-red-600 flex items-center gap-2"
          >
            <LogoutIcon sx={{ fontSize: 20 }} />
            Log Out
          </button>
        )}
      </div>
    </div>
  );
};
