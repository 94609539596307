import { create, StateCreator } from 'zustand';
import { fetchData, getTokenValues, postData, randomTimeout } from '../utils';

export interface IItem {
  _id: string;
  name?: string;
  images?: string;
  active?: boolean;
  description?: string;
  tags?: string[];
  score?: number;
  charId?: string;
  likes?: number;
}

export interface CharacterSlice {
  characters: IItem[];
  characterPosts: { [key: string]: IItem[] };
  activeCharacter?: IItem;
  me?: IItem;
  getCharacters: () => Promise<void>;
  getCharacter: (id: string) => Promise<IItem | undefined>;
  getMe: () => Promise<void>;
  getCharacterPosts: (id: string, pageSize?: number) => void;
  followCharacter: (id: string) => Promise<void>;
  setActiveCharacter: (charId: string) => void;
}

export interface FeedSlice {
  feed: IItem[];
  updateFeed: (page?: number, pageSize?: number) => Promise<void>;
}

export interface InboxMessage {
  name: string;
  _id: string;
  message: string;
}

export interface UserSlice extends UserValues {
  setUserValues: (values: UserValues) => void;
  initUser: () => void;
  logout: () => void;
}

export interface UserValues {
  token?: string;
  tier?: number;
  user?: string;
}

export interface ChatSlice {
  messages: { response: string; role: string }[];
  clearMessages: () => void;
  horny: number;
  options: string[];
  locationSelect: boolean;
  actionSelect: boolean;
  timeout?: number;
  typing: boolean;
  inbox: InboxMessage[];
  stopTyping: () => void;
  getInbox: () => Promise<void>;
  sendChatMessage: (id: string, message?: string) => Promise<any>;
}

export interface ErrorSlice {
  error: string;
  cause: string;
  setError: (message: string, cause?: string) => void;
  clearError: () => void;
}

export const createUserSlice: StateCreator<UserSlice, [], [], UserSlice> = (
  set
) => ({
  initUser: () => {
    const { tier, token, user } = getTokenValues();
    set({ tier, token, user });
  },
  setUserValues: (values: UserValues) => {
    set(values);
  },
  logout: () => {
    localStorage.removeItem('token');
    window.location.reload();
    set({ tier: undefined, token: undefined, user: undefined });
  },
});

export const createCharacterSlice: StateCreator<
  CharacterSlice,
  [],
  [],
  CharacterSlice
> = (set, get) => ({
  characters: [],
  characterPosts: {},
  activeCharacter: undefined,
  me: undefined,
  getMe: async () => {
    const me = await fetchData('/api/character/get/me');
    set({ me });
  },
  getCharacters: async () => {
    const characters = await fetchData('/api/character/all');
    set((_) => ({ characters }));
  },
  getCharacter: async (id: string) => {
    if (get().characters.find((char) => char._id === id && id !== 'me')) {
      return get().characters.find((char) => char._id === id);
    }
    const character = await fetchData(`/api/character/get/${id}`);
    set({
      activeCharacter: character,
      characters: [
        ...get().characters.filter((char) => char._id !== id),
        character,
      ],
    });
    return character;
  },
  getCharacterPosts: async (id: string, pageSize = 10) => {
    const existingPosts = get().characterPosts[id] || [];
    const currentPage = Math.floor(existingPosts.length / pageSize) + 1;

    // If the last page wasn't full, we've reached the end
    if (existingPosts.length > 0 && existingPosts.length % pageSize !== 0) {
      return;
    }

    const posts = await fetchData(
      `/api/character/posts/${id}?page=${currentPage}&pageSize=${pageSize}`
    );

    set((state) => ({
      characterPosts: {
        ...state.characterPosts,
        [id]: [
          ...(state.characterPosts[id] || []),
          ...(posts.length ? posts : [{ _id: null }]),
        ],
      },
    }));
  },
  followCharacter: async (id: string) => {
    const character = await fetchData(`/api/character/follow/${id}`);
    set((state) => ({
      characters: state.characters.map((char) =>
        char._id === id ? { ...char, active: character.active } : char
      ),
      activeCharacter:
        state.activeCharacter?._id === id
          ? { ...state.activeCharacter, active: character.active }
          : state.activeCharacter,
    }));
    return character;
  },
  setActiveCharacter: async (charId: string) => {
    set({ activeCharacter: await get().getCharacter(charId) });
  },
});

export const createFeedSlice: StateCreator<FeedSlice, [], [], FeedSlice> = (
  set,
  get
) => ({
  feed: [],
  updateFeed: async (page = 1, pageSize = 10) => {
    const feedItems: IItem[] = await fetchData(
      `/api/feed?page=${page}&pageSize=${pageSize}`
    );
    set((_) => ({
      feed: get()
        .feed.filter((item) => !feedItems.find(({ _id }) => _id === item._id))
        .concat(feedItems),
    }));
  },
});

export const createChatSlice: StateCreator<ChatSlice, [], [], ChatSlice> = (
  set,
  get
) => ({
  typing: false,
  messages: [],
  clearMessages: () => {
    set({ messages: [], timeout: undefined, typing: false });
  },
  stopTyping: () => {
    set({ typing: false });
  },
  horny: 0,
  options: [],
  locationSelect: false,
  actionSelect: false,
  inbox: [],
  getInbox: async () => {
    const inbox = await fetchData('/api/message/inbox');
    set({ inbox });
  },
  sendChatMessage: async (id: string, message = '') => {
    if (get().messages.length > 0) {
      randomTimeout(500, 1500, () => {
        set(() => ({
          typing: true,
        }));
      });
    }
    // Add user message immediately if it exists
    if (message && !get().actionSelect) {
      set(() => ({
        messages: [...get().messages, { response: message, role: 'user' }],
      }));
    }
    set(() => ({ options: [] }));

    const response = await postData(`/api/message/chat/${id}`, { message });
    const existingMessages = get().messages;
    if (!existingMessages.length) {
      set(() => ({
        messages: response.messages,
        horny: response.horny,
        options: response.options,
        locationSelect: !!response.locationSelect,
        actionSelect: !!response.actionSelect,
        timeout: response.timeout,
      }));
      return;
    }
    const newMessages = response.messages.filter(
      (msg: any) =>
        !existingMessages.find((existing) => existing.response === msg.response)
    );
    set(() => ({ typing: false }));
    // Add first message immediately
    if (newMessages.length > 0) {
      set((state) => ({
        messages: [...state.messages, newMessages[0]],
        horny: response.horny,
        locationSelect: !!response.locationSelect,
      }));
    }

    // Process remaining messages with delays
    if (newMessages.length > 1) {
      for (let i = 1; i < newMessages.length; i++) {
        const msg = newMessages[i];
        if (msg.role === 'character') {
          set(() => ({ typing: true }));
        }
        if (!msg.response.includes('.jpg')) {
          await randomTimeout(700, 700 + msg.response.length * 50);
        } else {
          await randomTimeout(500, 800);
        }

        set((state) => ({
          messages: [...state.messages, msg],
          typing: false,
        }));
        if (i === newMessages.length - 1) {
          set(() => ({
            timeout: response.timeout,
            options: response.options,
            actionSelect: !!response.actionSelect,
          }));
        }
      }
    } else {
      set(() => ({
        timeout: response.timeout,
        options: response.options,
        actionSelect: !!response.actionSelect,
      }));
    }
    return response;
  },
});

export const createErrorSlice: StateCreator<ErrorSlice, [], [], ErrorSlice> = (
  set
) => ({
  error: '',
  cause: '',
  setError: (message: string, cause?: string) => set({ error: message, cause }),
  clearError: () => set({ error: '', cause: '' }),
});

export type MainStore = CharacterSlice &
  UserSlice &
  FeedSlice &
  ChatSlice &
  ErrorSlice & {
    likePost: (id: string) => Promise<void>;
    sendMessage: (id: string, message?: string) => Promise<any>;
  };
export const useMainStore = create<MainStore>()((...a) => {
  const [set, get] = a;
  return {
    ...createCharacterSlice(...a),
    ...createFeedSlice(...a),
    ...createChatSlice(...a),
    ...createUserSlice(...a),
    ...createErrorSlice(...a),
    likePost: async (id: string) => {
      const response = await fetchData(`/api/feed/like/${id}`);
      const { likes } = response;
      set((state) => ({
        feed: state.feed.map((item) =>
          item._id === id ? { ...item, likes } : item
        ),
        characterPosts: {
          ...state.characterPosts,
          [state.activeCharacter?._id || '']: state.characterPosts[
            state.activeCharacter?._id || ''
          ]?.map((item) => (item._id === id ? { ...item, likes } : item)),
        },
      }));
    },
    sendMessage: async (id: string, message = '') => {
      const response = await get().sendChatMessage(id, message);
      set(() => ({
        characters: get().characters.map((char) =>
          char._id === 'me' ? { ...char, score: response.score } : char
        ),
      }));
    },
  };
});
