import { Outlet } from 'react-router-dom';
import { Header } from './Header';

export const Layout = () => {
  return (
    <>
      <div className="mx-auto max-w-[600px] px-4">
        <Header />
        <Outlet />
      </div>
    </>
  );
};
