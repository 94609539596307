import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { Layout } from './components/Layout';
import { Home } from './components/Home';
import { AppLayout } from './components/AppLayout';
import { Feed } from './components/Feed';
import { Profile } from './components/Profile';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { Chat } from './components/Chat';
import { Inbox } from './components/Inbox';
import { Explore } from './components/Explore';
import { ErrorModal } from './components/ErrorModal';
import { Button, Typography } from '@mui/material';
import { Terms } from './components/Terms';
import { Privacy } from './components/Privacy';
import { useMainStore } from './stores/mainStore';

// Create a theme instance
const theme = createTheme({
  // You can customize the theme here
});

export const App: React.FC = () => {
  const [showTosModal, setShowTosModal] = useState(false);
  const initUser = useMainStore((state) => state.initUser);

  useEffect(() => {
    // Check TOS agreement on component mount
    const tosAgreed = localStorage.getItem('tosAgree') === 'true';
    if (!tosAgreed) {
      setShowTosModal(true);
    }
    initUser();
  }, []);

  const handleTosAgree = () => {
    localStorage.setItem('tosAgree', 'true');
    setShowTosModal(false);
  };

  return (
    <ThemeProvider theme={theme}>
      <Router>
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route index element={<Home />} />
            <Route path="terms" element={<Terms />} />
            <Route path="privacy" element={<Privacy />} />
          </Route>
          <Route path="/app" element={<AppLayout />}>
            <Route path="home" element={<Feed show="following" />} />
            <Route path="feed" element={<Feed show="all" />} />
            <Route path="explore" element={<Explore />} />
            <Route path="inbox" element={<Inbox />} />
            <Route path="profile/:id" element={<Profile />} />
            <Route path="chat/:charId" element={<Chat />} />
          </Route>
          <Route path="*" element={<div>Page Not Found</div>} />
        </Routes>
      </Router>
      <ErrorModal />
      {showTosModal &&
        window.location.pathname !== '/terms' &&
        window.location.pathname !== '/privacy' && (
          <div className="absolute top-0 left-0 flex justify-center items-center w-screen h-screen bg-white/80 backdrop-blur-md z-50 p-8 box-border text-center">
            <div className="max-w-[480px] container" id="container">
              <img
                src="/public/adult-sign-icon.svg"
                alt="18+"
                className="w-24 mx-auto mb-4"
              />
              <Button
                variant="outlined"
                onClick={handleTosAgree}
                sx={{
                  borderColor: 'black',
                  color: 'black',
                  mb: 2,
                  borderRadius: '25px',
                  fontSize: '1.2rem',
                  padding: '8px 30px',
                }}
              >
                Continue
              </Button>
              <Typography
                variant="body1"
                sx={{ fontSize: '1.2rem' }}
                className="text-gray-900"
              >
                This website contains age-restricted materials. Please leave the
                page immediately if you're a minor. By continuing you hereby
                agree to comply with all the{' '}
                <b>
                  <a href="/terms">TERMS AND CONDITIONS</a>
                </b>
                .
              </Typography>
            </div>
          </div>
        )}
    </ThemeProvider>
  );
};
