import { Typography } from '@mui/material';
import { Link } from 'react-router-dom';

export const Terms = () => {
  return (
    <div className="container mx-auto px-4 py-8">
      <Typography variant="h4" sx={{ mb: 4, textAlign: 'center' }}>
        Terms and Conditions
      </Typography>
      <Typography variant="body1" sx={{ mb: 4 }}>
        Website "LewdSpace.com" - "Site" or “Website, owners of the Site - "we"
        or "us". By using the Site, you agree to be bound by these Terms of
        Service and to use the Site in accordance with these Terms of Service,
        our{' '}
        <Link to="/privacy" className="underline">
          Privacy Policy
        </Link>{' '}
        and any additional terms and conditions that may apply to specific
        sections of the Site or to products and services available through the
        Site. Accessing the Site, in any manner, whether automated or otherwise,
        constitutes use of the Site and your agreement to be bound by these
        Terms of Service. We reserve the right to change these Terms of Service
        or to impose new conditions on use of the Site, from time to time, in
        which case we will post the revised Terms of Service on this revised
        Terms of Service on this website. By continuing to use the Site after we
        post any such changes, you accept the Terms of Service, as modified.
      </Typography>
      <Typography variant="body1" sx={{ mb: 4 }}>
        This Website contains age-restricted materials including nudity and
        explicit depictions of sexual activity. User hereby acknowledges and
        understands the explicit sexual nature of the materials available on
        this Website and agrees to comply with these terms and conditions.
      </Typography>
      <Typography variant="body1" sx={{ mb: 4 }}>
        Throughout the Site, we may provide links and pointers to Internet sites
        maintained by third parties. Our linking to such third-party sites does
        not imply an endorsement or sponsorship of such sites, or the
        information, products or services offered on or through the sites. In
        addition, we do not operate or control in any respect any information,
        products or services that third parties may provide on or through the
        Site or on websites linked to by us on the Site.
      </Typography>
      <Typography variant="body1" sx={{ mb: 4 }}>
        The information on or through the site and any third-party sites are
        provided "as is" and without warranties of any kind either express or
        implied. To the fullest extent permissible pursuant to applicable law,
        we disclaim all warranties, express or implied, including, but not
        limited to, implied warranties of merchantability and fitness for a
        particular purpose. We do not warrant that the site or any of its
        functions will be uninterrupted or error-free, that defects will be
        corrected, or that any part of this site, or the servers that make it
        available, are free of viruses or other harmful components. We do not
        warrant or make any representations regarding the use or the results of
        the use of the site or materials on this site or on third-party sites in
        terms of their correctness, accuracy, timeliness, reliability or
        otherwise.
      </Typography>
      <Typography variant="body1" sx={{ mb: 4 }}>
        Under no circumstances, including, but not limited to negligence shall
        we be liable for any direct, indirect, incidental, special or
        consequential damages that result from the use of, or the inability to
        use the site. If you are dissatisfied with the site, any materials,
        products, or services on the site, or with any of the site’s terms and
        conditions, your sole and exclusive remedy is to discontinue using the
        site and the products, services and/or materials.
      </Typography>
      <Typography variant="h5" sx={{ mb: 2, textAlign: 'center' }}>
        Ability to Accept Terms of Service
      </Typography>
      <Typography variant="body1" sx={{ mb: 4 }}>
        You affirm that you are at least 18 years of age or the age of majority
        in the jurisdiction you are accessing this Website from and are fully
        able and competent to enter into the terms, conditions, obligations,
        affirmations, representations, and warranties set forth in these Terms
        of Service, and to abide by and comply with these Terms of Service. If
        you are under 18 or the applicable age of majority, please do not use
        this Website. You also represent that the jurisdiction from which you
        access this Website does not prohibit the receiving or viewing of
        sexually explicit Content. We may require that you provide us and/or our
        third-party age verification service providers with information which
        will help us determine that you are over the age of majority required to
        have access to this Website and to view its Contents. For more
        information on how this information is processed, please review our{' '}
        <Link to="/privacy" className="underline">
          Privacy Policy
        </Link>
        . Failure to, when required, to provide us and/or our third-party age
        verification service providers with information which will help us
        determine that you are over the age of majority required to have access
        to this Website and to view its Contents will lead to the termination or
        suspension of your access rights to all or part of this Website.
      </Typography>
      <Typography variant="body1" sx={{ mb: 4 }}>
        You hereby certify under unsworn declaration of perjury the following
        that you are requesting adult-oriented materials for your own private
        enjoyment and that you will never share these materials with a minor or
        in ANY WAY make these materials available to a minor.
      </Typography>
      <Typography variant="body1" sx={{ mb: 4 }}>
        You understand and acknowledge that when using this Website, you will be
        exposed to Content that is inaccurate, offensive, indecent, or
        objectionable, and you agree to waive, and hereby do waive, any legal or
        equitable rights or remedies you have or may have against this Website
        with respect thereto, and agree to indemnify and hold this Website, its
        service providers harmless to the fullest extent allowed by law
        regarding all matters related to your use of this Website.
      </Typography>
      <Typography variant="h5" sx={{ mb: 2, textAlign: 'center' }}>
        18 U.S.C. Section 2257 Compliance Notice
      </Typography>
      <Typography variant="body1" sx={{ mb: 4 }}>
        Any sexually explicit voice recording audio content are recordings of
        actors/actresses over the age of eighteen years at the time of said
        recordings. All sexually explicit images displayed by the software and
        the website are completely computer generated, the creation process of
        which never involved male or female live models of any kind. Some visual
        and audio depictions displayed in the product are exempt from the
        provision of 18 U.S.C. section 2257 and 28 C.F.R. 75 because said visual
        depictions do not consist of depictions of conduct as specifically
        listed in 18 U.S.C section 2256 (2) (A) through (D), but are merely
        depictions of non-sexually explicit nudity, or are depictions of
        simulated sexual conduct. The owners and operators of this site are the
        primary producer of most of the visual content contained in the product.
      </Typography>
      <Typography variant="h5" sx={{ mb: 2, textAlign: 'center' }}>
        Accessing this Website
      </Typography>
      <Typography variant="body1" sx={{ mb: 4 }}>
        We reserve the right to withdraw or amend this Website, and any service
        or material we provide on this Website, in our sole discretion without
        notice. We will not be liable if for any reason all or any part of this
        Website is unavailable at any time or for any period. From time to time,
        we may restrict access to some parts of this Website, or the entire
        Website.
      </Typography>
      <Typography variant="body1" sx={{ mb: 4 }}>
        You are responsible for making all arrangements necessary for you to
        have access to this Website, and ensuring that all persons who access
        this Website through your internet connection are aware of these Terms
        of Service and comply with them.
      </Typography>
      <Typography variant="body1" sx={{ mb: 4 }}>
        By using LewdSpace.com you are indicating that you have read the “Terms
        of Service” in its entirety, uderstand its terms, consent to all the
        terms and conditions, represent and warrant that you are currently over
        the age of 18 years and understand that materials presented
        LewdSpace.com include nudity, visual and audio presentation of sexual
        situations and adult language. If you do not agree to all of the terms
        and conditions set forth in this agreement, are not over 18 years of
        age, or are in a location where such materials are prohibited, then
        cease all attempts to view or use this Website and leave immediately
      </Typography>
    </div>
  );
};
