import { useEffect, useRef, useState } from 'react';
import { SuggestionList } from './SuggestionList';
import { MainStore, useMainStore } from '../stores/mainStore';
import { IItem } from '../stores/mainStore';
import { FeedItem } from './FeedItem';

export const Feed = ({ show = 'all' }: { show?: 'all' | 'following' }) => {
  const feed = useMainStore((state: MainStore) => state.feed);
  const characters = useMainStore((state: MainStore) => state.characters);
  const updateFeed = useMainStore((state: MainStore) => state.updateFeed);
  const getCharacters = useMainStore((state: MainStore) => state.getCharacters);
  const [page, setPage] = useState(0);
  const containerRef = useRef<HTMLDivElement>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [lastLoadTime, setLastLoadTime] = useState(0);

  useEffect(() => {
    setPage(Math.ceil(feed.length / 10));
  }, [feed]);

  useEffect(() => {
    getCharacters();
  }, []);

  useEffect(() => {
    loadMoreItems();
  }, []);

  const loadMoreItems = async () => {
    const now = Date.now();
    if (isLoading || now - lastLoadTime < 2000) return;

    setIsLoading(true);
    setLastLoadTime(now);
    await updateFeed(page + 1);
    setIsLoading(false);
  };

  const handleScroll = () => {
    if (containerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = containerRef.current;
      if (scrollTop + clientHeight >= scrollHeight - 10) {
        loadMoreItems();
      }
    }
  };

  const getCharacter = (charId: string | undefined): IItem | undefined => {
    return characters.find((char) => char._id === charId);
  };

  return (
    <div
      ref={containerRef}
      onScroll={handleScroll}
      className="app-container flex flex-col flex-grow overflow-auto pb-8"
    >
      <SuggestionList show={show} />
      {feed.reduce((acc: JSX.Element[], item: IItem, index, array) => {
        const character = getCharacter(item.charId);
        if ((show === 'following' && character?.active) || show === 'all') {
          acc.push(
            <FeedItem key={item._id} item={item} character={character} />
          );
        }
        if (
          index === array.length - 1 &&
          show === 'following' &&
          acc.length === 0
        ) {
          acc.push(
            <FeedItem key={item._id} item={item} character={character} />
          );
        }
        return acc;
      }, [])}
    </div>
  );
};
