import { Link, useLocation } from 'react-router-dom';

export interface IBottomNavigation {
  items: {
    // react component
    icon: JSX.Element;
    name: string;
  }[];
}
const items = [
  {
    icon: 'home-outline',
    name: 'home',
  },
  {
    icon: 'layers-outline',
    name: 'feed',
  },
  {
    icon: 'compass-outline',
    name: 'explore',
  },
  {
    icon: 'email-outline',
    name: 'inbox',
  },
  {
    icon: 'account-circle-outline',
    name: 'account',
    link: 'profile/me',
  },
];

export const BottomNav = () => {
  const location = useLocation();
  return (
    <div className="absolute inset-x-0 -bottom-8 pb-4 z-50">
      <div className="bg-white shadow-lg rounded-2xl">
        <div className="container mx-auto">
          <div className="flex justify-between px-4">
            {items.map((item) => (
              <div key={item.name} className="flex group">
                <Link
                  to={`/app/${item.link || item.name}`}
                  className={`pt-1 pb-2 ${
                    location.pathname === `/app/${item.name}`
                      ? 'text-yellow-500'
                      : 'text-gray-400 hover:text-yellow-500'
                  }`}
                >
                  <span className="flex flex-col items-center">
                    <i
                      className={`mdi mdi-${item.icon} mdi-24px mx-1 ${
                        location.pathname === `/app/${item.name}`
                          ? 'text-gray-700'
                          : 'text-gray-500 group-hover:text-gray-700'
                      } transition-color duration-200`}
                    ></i>
                    <span className="text-xs transition-all duration-200">
                      {item.name.charAt(0).toUpperCase() + item.name.slice(1)}
                    </span>
                  </span>
                </Link>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};
