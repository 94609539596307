import { IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

export const CloseButton = () => {
  return (
    <IconButton
      onClick={() => {
        window.history.back();
      }}
      sx={{
        position: 'absolute',
        top: '10px',
        right: '10px',
        color: 'black',
        padding: '12px',
        '& .MuiSvgIcon-root': {
          fontSize: '28px',
        },
      }}
    >
      <CloseIcon />
    </IconButton>
  );
};
