import { useMainStore } from '../stores/mainStore';
import { getImageSrc } from '../utils';
import { useMobileLikeScroller } from '../hooks/useMobileLikeScroller';
import { Link } from 'react-router-dom';

export const SuggestionList = ({
  show = 'all',
}: {
  show?: 'all' | 'following';
}) => {
  const characters = useMainStore((state) => state.characters);
  const me = characters.find((char) => char._id === 'me');
  const scrollContainerRef = useMobileLikeScroller({ direction: 'x' });

  return (
    <div className="border-b border-gray-100 h-32 flex flex-col">
      <div
        ref={scrollContainerRef}
        className="flex-1 overflow-x-auto suggestion-list cursor-grab"
      >
        <ul className="flex items-center space-x-4 px-4 h-full w-max">
          {characters
            .filter((character) =>
              show === 'following'
                ? (character.score || 0) <= (me?.score || 0) &&
                  character._id !== 'me'
                : character._id !== 'me'
            )
            .map((character) => (
              <li
                key={character._id}
                className="flex flex-col items-center justify-center h-full"
              >
                <Link
                  to={`/app/profile/${character._id}`}
                  className="story-ring flex justify-center items-center
                        bg-gradient-to-tl from-yellow-200 to-orange-500 
                        rounded-full relative cursor-pointer
                        hover:from-orange-300 hover:to-red-400
                        transition-all duration-150 delay-100
                        w-20 h-20 flex-shrink-0"
                >
                  <div className="block bg-white p-0.5 rounded-full w-[90%] h-[90%]">
                    <div className="w-full h-full overflow-hidden rounded-full relative">
                      <img
                        className="absolute w-full top-0 left-1/2 -translate-x-1/2"
                        src={getImageSrc(character.images?.split('|')[0]) || ''}
                        alt={character.name}
                      />
                    </div>
                  </div>
                </Link>

                <Link
                  to={`/app/profile/${character._id}`}
                  className="story-text text-sm truncate w-20 text-center mt-1"
                >
                  {character.name}
                </Link>
              </li>
            ))}
        </ul>
      </div>
    </div>
  );
};
