import { useRef, useEffect, useCallback } from 'react';

interface ScrollerOptions {
  direction?: 'x' | 'y' | 'xy';
}

export function useMobileLikeScroller({
  direction = 'x',
}: ScrollerOptions = {}) {
  const containerRef = useRef<HTMLDivElement>(null);
  const isDraggingRef = useRef(false);
  const previousTouchRef = useRef({
    x: [0, 0, 0],
    y: [0, 0, 0],
    time: [0, 0, 0],
  });
  const scrollAtStartRef = useRef([0, 0]);
  const inertialTimerRef = useRef<number | null>(null);

  const handleMouseDown = useCallback((e: MouseEvent) => {
    if (e.button !== 0 || !containerRef.current) return; // Only handle left click

    e.preventDefault();
    isDraggingRef.current = true;
    containerRef.current.style.cursor = 'grabbing';

    const { pageX, pageY } = e;
    previousTouchRef.current = {
      x: [pageX, pageX, pageX],
      y: [pageY, pageY, pageY],
      time: [Date.now() - 2, Date.now() - 1, Date.now()],
    };

    if (inertialTimerRef.current) {
      cancelAnimationFrame(inertialTimerRef.current);
      inertialTimerRef.current = null;
    }
  }, []);

  const handleMouseMove = useCallback(
    (e: MouseEvent) => {
      if (!isDraggingRef.current || !containerRef.current) return;

      const { pageX, pageY } = e;
      const { x, y, time } = previousTouchRef.current;

      previousTouchRef.current = {
        x: [x[1], x[2], pageX],
        y: [y[1], y[2], pageY],
        time: [time[1], time[2], Date.now()],
      };

      if (direction !== 'y') containerRef.current.scrollLeft -= pageX - x[2];
      if (direction !== 'x') containerRef.current.scrollTop -= pageY - y[2];
    },
    [direction]
  );

  const handleMouseUp = useCallback(() => {
    if (!containerRef.current) return;

    isDraggingRef.current = false;
    containerRef.current.style.cursor = '';

    scrollAtStartRef.current = [
      containerRef.current.scrollLeft,
      containerRef.current.scrollTop,
    ];
    inertialMove();
  }, []);

  const inertialMove = useCallback(() => {
    if (!containerRef.current) return;

    const { x, y, time } = previousTouchRef.current;
    const container = containerRef.current;
    const [scrollLeftStart, scrollTopStart] = scrollAtStartRef.current;

    const vX = direction !== 'y' ? (x[2] - x[0]) / (time[2] - time[0]) : 0;
    const vY = direction !== 'x' ? (y[2] - y[0]) / (time[2] - time[0]) : 0;

    const av0 = Math.sqrt(vX * vX + vY * vY);
    const unitVector = [vX / av0, vY / av0];
    const cappedAv0 = Math.min(12, Math.max(-12, 1.2 * av0));

    const animate = (currentTime: number) => {
      const t = (currentTime - time[2]) / 1000;
      const v =
        cappedAv0 -
        14.278 * t +
        (75.24 * t * t) / cappedAv0 -
        (149.72 * t * t * t) / (cappedAv0 * cappedAv0);

      if (av0 === 0 || v <= 0 || isNaN(av0)) {
        inertialTimerRef.current = null;
        return;
      }

      const deltaX =
        container.clientWidth *
        unitVector[0] *
        (cappedAv0 * t -
          7.1397 * t * t +
          (25.08 * t * t * t) / cappedAv0 -
          (37.43 * t * t * t * t) / (cappedAv0 * cappedAv0));
      const deltaY =
        container.clientHeight *
        unitVector[1] *
        (cappedAv0 * t -
          7.1397 * t * t +
          (25.08 * t * t * t) / cappedAv0 -
          (37.43 * t * t * t * t) / (cappedAv0 * cappedAv0));

      const maxScrollX = container.scrollWidth - container.clientWidth;
      const maxScrollY = container.scrollHeight - container.clientHeight;

      const newScrollLeft = Math.min(
        maxScrollX,
        Math.max(0, scrollLeftStart - deltaX)
      );
      const newScrollTop = Math.min(
        maxScrollY,
        Math.max(0, scrollTopStart - deltaY)
      );

      if (
        (newScrollLeft === 0 || newScrollLeft === maxScrollX) &&
        (newScrollTop === 0 || newScrollTop === maxScrollY)
      ) {
        inertialTimerRef.current = null;
        return;
      }

      if (direction !== 'y') container.scrollLeft = newScrollLeft;
      if (direction !== 'x') container.scrollTop = newScrollTop;

      inertialTimerRef.current = requestAnimationFrame(animate);
    };

    inertialTimerRef.current = requestAnimationFrame(animate);
  }, [direction]);

  useEffect(() => {
    const container = containerRef.current;
    if (!container) return;

    container.addEventListener('mousedown', handleMouseDown);
    window.addEventListener('mousemove', handleMouseMove);
    window.addEventListener('mouseup', handleMouseUp);

    return () => {
      container.removeEventListener('mousedown', handleMouseDown);
      window.removeEventListener('mousemove', handleMouseMove);
      window.removeEventListener('mouseup', handleMouseUp);
      if (inertialTimerRef.current) {
        cancelAnimationFrame(inertialTimerRef.current);
      }
    };
  }, [handleMouseDown, handleMouseMove, handleMouseUp]);

  return containerRef;
}
