import React, { useState, useRef, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useMainStore, IItem } from '../stores/mainStore';
import { getImageSrc } from '../utils';
import {
  Typography,
  Button,
  Chip,
  TextField,
  CircularProgress,
  IconButton,
} from '@mui/material';
import { keyframes } from '@emotion/react';
import styled from '@emotion/styled';
import CloseIcon from '@mui/icons-material/Close';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Collapse } from '@mui/material';

const dotAnimation = keyframes`
  0%, 20% {
    content: '.';
  }
  40% {
    content: '..';
  }
  60%, 100% {
    content: '...';
  }
`;

const AnimatedDots = styled.span`
  margin-left: 8px;
  font-size: 1.2em;
  &::after {
    content: '.';
    animation: ${dotAnimation} 1s infinite;
    display: inline-block;
    width: 1.2em;
  }
`;

const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
`;

const Heart = styled.div`
  position: absolute;
  color: rgba(244, 114, 182, 0.2); // pink-300 with opacity
  font-size: 24px;
  user-select: none;
  z-index: 0;
`;

const HeartContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
  pointer-events: none;
`;

const FullscreenImage = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.9);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;

  img {
    max-width: 90vw;
    max-height: 90vh;
    object-fit: contain;
  }
`;

const CloseButton = styled(IconButton)`
  position: absolute;
  top: 16px;
  right: 16px;
  color: white;
`;

// Add this interface above the Chat component
interface HeartPosition {
  left: string;
  top: string;
}

export const Chat: React.FC = () => {
  const { charId } = useParams<{ charId: string }>();
  const navigate = useNavigate();
  const getCharacter = useMainStore((state) => state.getCharacter);
  const sendMessage = useMainStore((state) => state.sendMessage);
  const messages = useMainStore((state) => state.messages);
  const clearMessages = useMainStore((state) => state.clearMessages);
  const horny = useMainStore((state) => state.horny);
  const options = useMainStore((state) => state.options);
  const locationSelect = useMainStore((state) => state.locationSelect);
  const actionSelect = useMainStore((state) => state.actionSelect);
  const typing = useMainStore((state) => state.typing);
  const [character, setCharacter] = useState<IItem | undefined>(undefined);
  const [inputText, setInputText] = useState('');
  const messagesEndRef = useRef<HTMLDivElement>(null);
  const [isLoading, setIsLoading] = useState(false);
  const timeout = useMainStore((state) => state.timeout);
  const [timeRemaining, setTimeRemaining] = useState<string>('');
  const [heartPositions, setHeartPositions] = useState<HeartPosition[]>([]);
  const [selectedImage, setSelectedImage] = useState<string | null>(null);
  const [optionsExpanded, setOptionsExpanded] = useState(true);

  const formatTimeRemaining = (timeout: number) => {
    const now = Date.now();
    const remainingMs = timeout - now;
    const minutes = Math.ceil(remainingMs / (1000 * 60));
    return `${minutes} minute${minutes !== 1 ? 's' : ''}`;
  };

  useEffect(() => {
    const fetchCharacter = async () => {
      if (charId) {
        const char = await getCharacter(charId);
        setCharacter(char);
        clearMessages();
        sendMessage(charId);
        setTimeRemaining('');
      }
    };
    fetchCharacter();
  }, [charId]);

  useEffect(() => {
    // Add a small delay to ensure the DOM has updated
    const timeoutId = setTimeout(() => {
      messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
    }, 100);

    return () => clearTimeout(timeoutId);
  }, [messages]);

  useEffect(() => {
    if (!timeout) return;

    const checkTimeout = () => {
      const now = Date.now();
      if (now >= timeout && charId) {
        setTimeRemaining('');
        sendMessage(charId);
      } else {
        setTimeRemaining(formatTimeRemaining(timeout));
      }
    };

    const intervalId = setInterval(() => {
      checkTimeout();
    }, 30000); // Check every 30 seconds

    // Initial check
    checkTimeout();

    // Cleanup interval on unmount
    return () => clearInterval(intervalId);
  }, [timeout, charId, sendMessage]);

  const handleOptionClick = (option: string) => {
    setInputText(option);
  };

  const handleSendMessage = () => {
    if (inputText && charId) {
      setIsLoading(true);
      sendMessage(charId, inputText).finally(() => {
        setIsLoading(false);
      });
      setInputText('');
    }
  };

  const renderHearts = () => {
    // Generate positions if they haven't been set yet
    if (heartPositions.length === 0) {
      const newPositions = Array.from({ length: 20 }, () => ({
        left: `${Math.random() * 100}%`,
        top: `${Math.random() * 100}%`,
      }));
      setHeartPositions(newPositions);
      return newPositions.map((pos, i) => (
        <Heart key={i} style={pos}>
          ❤
        </Heart>
      ));
    }

    // Reuse existing positions
    return heartPositions.map((pos, i) => (
      <Heart key={i} style={pos}>
        ❤
      </Heart>
    ));
  };

  const handleImageClick = (imageSrc: string) => {
    setSelectedImage(imageSrc);
  };

  if (!character) {
    return <Typography>Loading...</Typography>;
  }

  return (
    <div
      className={`flex flex-col app-container pb-10 relative ${actionSelect ? 'bg-pink-100' : ''}`}
    >
      {actionSelect && <HeartContainer>{renderHearts()}</HeartContainer>}
      {messages.length === 0 && (
        <Overlay>
          <CircularProgress size={60} />
        </Overlay>
      )}
      {isLoading && locationSelect && (
        <Overlay>
          <div className="flex flex-col items-center">
            <CircularProgress size={60} />
            <Typography variant="h6" className="mt-4">
              A few minutes later...
            </Typography>
          </div>
        </Overlay>
      )}
      <div className="flex items-center justify-between p-4 border-b">
        <div className="flex items-center">
          <img
            src={getImageSrc(character.images)}
            alt={character.name}
            className="w-12 h-12 rounded-full mr-3 object-cover"
          />
          <Typography
            variant="h6"
            style={{
              display: 'flex',
              alignItems: 'center',
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis',
              overflow: 'hidden',
            }}
          >
            {character.name}
          </Typography>
        </div>
        <Button onClick={() => navigate(-1)}>Close</Button>
      </div>
      <div className="flex-grow overflow-y-auto p-4 pb-0 relative">
        {messages.map(
          (message, index) =>
            message.response && (
              <div
                key={index}
                className={`flex ${message.role === 'user' ? 'justify-end' : 'justify-start'} mb-4`}
              >
                <div
                  className={`max-w-xs p-3 rounded-lg ${message.role === 'user' ? 'bg-blue-500 text-white' : 'bg-gray-200'}`}
                >
                  {message.response
                    .split(' ')
                    .map((word, wordIndex) =>
                      word.includes('.jpg') ? (
                        <img
                          key={wordIndex}
                          src={getImageSrc(word)}
                          alt={`Image ${wordIndex}`}
                          className="max-w-full h-auto mb-2 cursor-pointer"
                          onClick={() => handleImageClick(getImageSrc(word))}
                        />
                      ) : (
                        <React.Fragment key={wordIndex}>{word} </React.Fragment>
                      )
                    )}
                </div>
              </div>
            )
        )}
        <div ref={messagesEndRef} />
      </div>
      <div className="border-t p-4 pt-0">
        {options.length > 0 && (
          <>
            <Button
              onClick={() => setOptionsExpanded(!optionsExpanded)}
              sx={{
                padding: 0,
                mb: 1,
                width: '24px',
                height: '24px',
                minWidth: '24px',
                margin: '0 auto',
                display: 'block',
              }}
              variant="text"
            >
              <ExpandMoreIcon
                sx={{
                  transform: optionsExpanded
                    ? 'rotate(0deg)'
                    : 'rotate(180deg)',
                  transition: 'transform 0.3s',
                }}
              />
            </Button>
            <Collapse in={optionsExpanded}>
              <div className="flex flex-wrap gap-2 mb-2">
                {options.map((option, index) => (
                  <Chip
                    key={index}
                    label={option}
                    onClick={() => handleOptionClick(option)}
                    clickable
                    color="primary"
                    variant="outlined"
                    sx={{
                      height: 'auto',
                      '& .MuiChip-label': {
                        display: 'block',
                        whiteSpace: 'normal',
                        wordBreak: 'break-word',
                        lineHeight: 1.2,
                        padding: '8px 12px',
                      },
                    }}
                  />
                ))}
              </div>
            </Collapse>
          </>
        )}
        {typing && (
          <Typography variant="body2" className="mb-2 italic text-gray-500">
            {character.name} is typing
            <AnimatedDots />
          </Typography>
        )}
        {timeRemaining && (
          <Typography variant="body2" className="mb-2 italic text-gray-500">
            {horny <= 3
              ? `${character.name} blocked you `
              : `${character.name} is away `}
            ({timeRemaining})
          </Typography>
        )}
        <div className="flex">
          <TextField
            multiline
            minRows={1}
            maxRows={4}
            value={inputText}
            onChange={(e) => setInputText(e.target.value)}
            className="flex-grow mr-2"
            placeholder="Type your message..."
            variant="outlined"
          />
          <Button
            onClick={handleSendMessage}
            variant="contained"
            color="primary"
            disabled={!inputText || isLoading}
          >
            Send
          </Button>
        </div>
      </div>
      {selectedImage && (
        <FullscreenImage>
          <img src={selectedImage} alt="Fullscreen" />
          <CloseButton onClick={() => setSelectedImage(null)}>
            <CloseIcon />
          </CloseButton>
        </FullscreenImage>
      )}
    </div>
  );
};
